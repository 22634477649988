<template>
  <div class="request-from">
    <esmp-modal
      v-model="show"
      title="Выберите сотрудника, для которого создается заявка"
      :width="800"
      ok-text="Применить"
      class-name="request-from__modal"
      :disabled-ok-button="!user"
      @on-ok="saveUser"
    >
      <esmp-checkbox v-model="isFired" class="request-from__checkbox">
        Поиск по уволенным сотрудникам
      </esmp-checkbox>
      <esmp-select
        ref="select"
        class="request-from__select"
        clearable
        placeholder="Поиск сотрудников"
        filterable
        :loading="loading"
        :show-search-results="showSearchResults"
        :remote-method="waitUserPrint"
        @on-query-change="setQuery"
        @on-select="setSelectedVal"
        @keydown.enter.native="search"
      >
        <esmp-select-option
          v-for="r in searchList"
          :value="r.login"
          :label="r.fullName"
          :key="r.login"
        >
          <div class="request-from__item">
            <div class="request-from__user-contact">
              <span class="request-from__user-name">{{ r.fullName }}</span>
              <span class="request-from__user-email" v-if="r.email">{{ r.email }}</span>
              <span class="request-from__user-fired" v-if="r.fired">уволен</span>
            </div>
            <span class="request-from__user-appointment" v-if="r.appointmentName">{{ r.appointmentName }}</span>
          </div>
        </esmp-select-option>
      </esmp-select>
      <div
        v-if="user"
        class="request-from__selected-user"
      >
        <template>
          <esmp-user-avatar
            v-if="user.photoURL"
            :username="user.fullName"
            :image-url="user.photoURL"
            class="request-from__image"
          />
          <esmp-user-avatar
            v-else
            :username="user.fullName"
            class="request-from__image"
          />
        </template>
        <div class="request-from__info">
          <div class="request-from__name">
            {{ user.fullName }}
          </div>
          <div class="request-from__appointment">
            {{ user.appointmentName }}
          </div>
        </div>
      </div>
    </esmp-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'RequestFrom',
  props: {
    isShowedRequestFrom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFired: false,
      user: null,
      query: '',
      searchTimeoutId: null,
      loading: false,
      searchList: null,
      showSearchResults: false,
    };
  },
  computed: {
    ...mapState('user', ['selectedUser']),
    show: {
      get() {
        return this.isShowedRequestFrom;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: 'request-from', newValue });
      },
    },
  },
  watch: {
    query(val) {
      if (val === '') {
        this.clearSearch();
      }
    },
    selectedUser() {
      if (!this.selectedUser) {
        this.clearSearch();
      }
    },
  },
  methods: {
    ...mapActions('user', ['setSelectedUser']),
    ...mapActions('system', ['setLoading']),
    setSelectedVal(val) {
      this.user = this.searchList.find((item) => item.login === val.value);
      this.searchList = null;
    },
    setQuery(val) {
      this.query = val;
    },
    waitUserPrint() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (!this.query || this.query.length < 3) {
        this.showSearchResults = false;
      }
      if (this.query && this.query.length >= 3) {
        this.showSearchResults = true;
        this.loading = true;
        this.searchTimeoutId = setTimeout(() => {
          this.search();
        }, SEARCH_DELAY);
      } else {
        this.loading = false;
      }
    },
    async search() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query) {
        this.loading = true;
        const { data } = await this.$API.user.search({
          isFired: this.isFired,
          query: this.query,
        });
        this.searchList = data;
        this.loading = false;
      }
    },
    clearSearch() {
      this.user = null;
      this.searchList = null;
      this.$refs.select.reset();
      if (this.selectedUser) this.setSelectedUser(null);
    },
    async saveUser() {
      this.setLoading({ key: 'page', value: true });
      const { data } = await this.$API.user.info(this.user.login);
      this.setSelectedUser({ ...this.user, ...data });
      this.setLoading({ key: 'page', value: false });
    },
  },
};
</script>

<style lang="scss">
.request-from {
  $root: &;

  &__checkbox {
    margin-bottom: 26px;
  }

  &__select {
    position: relative;
  }

  &__selected-user {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 16px;
    width: 56px;
    height: 56px;
  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__appointment {
    font-size: 12px;
    line-height: 16px;
    color: $color-grayscale-40;
  }

  &__item {
    @include hover(true) {
      background-clip: red;
      #{$root}__user-name {
        color: $color-client-portal-logo;
      }
    }
  }

  &__user-contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__user-name {
    margin-right: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    transition: color $base-animation-time;
  }

  &__user-email {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-50;
    margin-right: 16px;
  }

  &__user-appointment {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-40;
  }

  &__user-fired {
    font-size: 12px;
    line-height: 16px;
    color: $color-red;
  }
}
</style>
